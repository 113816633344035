import React from "react";
import get from "lodash/get";
import { useRecordContext } from "ra-core";
import { useEditStateContext } from "../../../../context/EditStateContext";
import { EditTabProps } from "../../../../types/utilities";
import { FormTab } from "../../../form/CustomTabForm/FormTab";
import { PmsShowContent } from "./PmsShowContent";
import { PmsEditContent } from "./PmsEditContent";
import { PmsPatientCase } from "../../../../types/patientManagementSystems";
import { isManualIntakeEligibleBooking, isVnetAssociatedClient } from "../../helpers/utils";
import { PmsManualIntakeEditing } from "./PmsManualIntakeEditing";

export function PmsTab(props: EditTabProps) {
  const { currentState } = useEditStateContext();
  const record = useRecordContext();
  const pmsPatientCase : PmsPatientCase = get(record, "pmsPatientCase");

  const allowedEditingManualIntake = record.attributes.status === "PendingConsultation"
    && isVnetAssociatedClient(record.clients?.[0])
    && isManualIntakeEligibleBooking(record)
    && !pmsPatientCase.caseReference;

  const editable = pmsPatientCase.isAutomaticallyCreated || !allowedEditingManualIntake ? "hidden" : "editable";


  if (currentState === "show") {
    return (
      <FormTab {...props} label="PMS" editable={editable}>
        <PmsShowContent />
      </FormTab>
    );
  }


  return (
    <FormTab {...props} label="PMS" editable={editable}>
      { allowedEditingManualIntake ? <PmsManualIntakeEditing /> : <PmsEditContent /> }
    </FormTab>
  );
}
