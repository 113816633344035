import { Labeled, TextField, TextInput } from "ra-ui-materialui";
import React, { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { Stack } from "../../layout/Stack";
import { Group } from "../../layout/Group";
import { Heading } from "../../ui/Heading";
import { Grid } from "@mui/material";
import { ClinicalAddressesInputs } from "../ClinicalAddressesInputs";
import { ConsultationTypeInput } from "../ConsultationTypeInput";
import { PatientDetailsInputs } from "../PatientDetailsInputs";
import { ReasonForBookingInput } from "../ReasonForBookingInput";
import { Identifier, useGetOne, useRecordContext } from "ra-core";
import { FullNameField } from "../../fields/FullNameField";
import DateField from "../../fields/DateField";
import { ReasonForMADInput } from "../ReasonForMADInput";
import { ClientEmployerInput } from "../ClientEmployerInput";
import { BookingAttachmentInput } from "../BookingAttachmentInput";
import { ClientSettingsDTO } from "../../../types/clientSettings";
import { VNetIntake } from "./VNetIntake";
import { isVnetAssociatedClient } from "../helpers/utils";

interface Props {
  appointmentId: Identifier | null;
  clientId: string;
  patientRecordId: string;
}

export function SchedulerBookingCreateForm(props: Props) {
  const { appointmentId, clientId, patientRecordId } = props;

  const { data: appointment } = useGetOne(
    "adminAppointments",
    {
      id: appointmentId,
    },
    { enabled: Boolean(appointmentId) }
  );

  const appointmentConsultationType = appointment?.attributes?.consultationType;

  const isAppointmentMAD = appointmentConsultationType === "MessageDoctor";

  const consultationType = useWatch({ name: "consultationType" });

  const isMAD = isAppointmentMAD || consultationType === "MessageDoctor";

  const { data: client } = useGetOne("clients", { id: clientId });
  const clientSettings = client?.clientSettings as ClientSettingsDTO["data"][];
  const collectProductId =
    clientSettings?.some(
      s =>
        s.attributes.key == "CollectProductId" && s.attributes.value == "true"
    ) ?? false;

  const showManualIntake = isVnetAssociatedClient(client) && appointment?.clinicalPractitioners[0]?.attributes?.specialties[0] === "Counsellor";

  const record = useRecordContext();
  useEffect(() => {
    if (isAppointmentMAD) {
      record.consultationType = "MessageDoctor";
    }
  }, [isAppointmentMAD, record]);

  return (
    <>
      <Stack>
        <Group heading={<Heading level={2}>General Information</Heading>}>
          <Grid item xs={12} sm={6}>
            <Labeled label="Patient Name">
              <TextField source="patientName" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateField
              record={appointment}
              source="attributes.start"
              label="Appointment Time"
              showTime
            />
          </Grid>
          <Grid item xs={12}>
            <Labeled label="Clinical Practitioner Name">
              <FullNameField
                firstNameSource="clinicalPractitioners[0].attributes.firstName"
                lastNameSource="clinicalPractitioners[0].attributes.lastName"
                record={appointment}
              />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label="Clinical Practitioner Specialty">
              <TextField
                source="clinicalPractitioners[0].attributes.specialties[0]"
                record={appointment}
              />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            {isAppointmentMAD ? (
              <Labeled label="Consultation Type">
                <TextField
                  source="attributes.consultationType"
                  record={appointment}
                />
              </Labeled>
            ) : (
              <ConsultationTypeInput appointmentConsultationType={appointmentConsultationType} clientId={clientId} patientRecordId={patientRecordId} />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label="Clinical Practitioner Gender">
              <TextField
                source="clinicalPractitioners[0].attributes.gender"
                record={appointment}
              />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            {isMAD ? <ReasonForMADInput /> : <ReasonForBookingInput />}
          </Grid>
          {collectProductId ? (
            <Grid item xs={12} sm={6}>
              <TextInput label="Product ID" source="productId" />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6}>
            <ClientEmployerInput />
          </Grid>
          {showManualIntake &&
            <Grid item xs={12} sm={6}>
              <VNetIntake />
            </Grid>}
        </Group>

        {isMAD ? (
          <>
            <Group heading={<Heading level={2}>Attachments</Heading>}>
              <BookingAttachmentInput />
            </Group>
          </>
        ) : (
          <>
            <ClinicalAddressesInputs />
            <PatientDetailsInputs />
          </>
        )}
      </Stack>
    </>
  );
}
