import { create } from "..";

const endpoint = "admin/vnet-intake/validate";


async function validateVNetIntake(args: any) {
  const body = {
    data: {
      type: "vnetIntakeValidations",
      attributes: {
        intakeReference: args.intakeReference
      }
    }
  };

  return await create(endpoint, body);
}

export default validateVNetIntake;