import { GetOneParams } from "react-admin";
import { get } from "../";

async function getPatientRecord(params: GetOneParams) {
  const { id: compKey } = params;
  const [id, clientId] = String(compKey).split(":");
  if (!id || id === "undefined") {
    return Promise.reject({
      status: 400,
      message: 'missing patient record id'
    });
  }

  const patientRecordsEndpoint = "/ms/patientRecords"

  const query = {
    "filter[id]": id,
    include: "client,user,dependants,dependants.dependantOf,dependantOf,patientManagementSystemPatientRecords,identityVerificationSessions.identityVerificationSessionChecks,identityVerificationPatients",
  };

  const response = await get(patientRecordsEndpoint, query);
  const patientRecord = response.data[0];

  const notesEndpoint = `/clients/${clientId}/users/${id}/patientRecords/${id}/notes`;
  const notes = await get(notesEndpoint, { include: "attachments" });

  patientRecord.notes = notes.data;
  patientRecord.prevNotes = notes.data;
  patientRecord.patientId = id;
  patientRecord.id = compKey;
  patientRecord.userCompKey = `${patientRecord.users[0].id}:${clientId}`;

  patientRecord.attributes.prevIdentityVerificationStatus = patientRecord.attributes.identityVerificationStatus;

  return {data: patientRecord};
}

export default getPatientRecord;
